export const menuItems = [
  {
    name: "https://accounts.gsmcoin.com/register",
    label: "Get Started",
    isExternal: true,
    hide:true,
  },
  {
    name: "https://accounts.gsmcoin.com/login",
    label: "Sign In",
    isExternal: true,
  },
  {
    name: "/terms-conditions",
    label: "Terms Condition",
    isExternal: false,
    hide:true,
  },
  {
    name: "/privacy-policy",
    label: "Privacy Policy",
    isExternal: false,
    hide:true,
  },
];
