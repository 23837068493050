import React from "react";
import { Link } from "gatsby";
import { Title} from "../Core";

import imgL1Logo from "../../assets/image/png/l1-logo.png";
import imgL1LogoWhite from "../../assets/image/svg/gsmc.svg";
// Fxied
const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      {white ? (
          <span>
              <Title variant="logo" > <img src={imgL1LogoWhite} alt="" />  GSM Coin</Title>
            </span>
          /* <img src={imgL1LogoWhite} alt="" />*/
      ) : (
          <span>

              <Title variant="logo" >  <img src={imgL1LogoWhite} alt="" /> GSM Coin</Title>
            </span>
       /* <img src={imgL1Logo} alt="" />*/
      )}
    </Link>
  );
};
export default Logo;
